import { addDoc, collection, getDocs, getFirestore } from "firebase/firestore";
import app from "../firebase";

const base = getFirestore(app);

export const createPaymentSession = async (
  quantity: number,
  email: string,
  docId: string
) => {
  return await fetch(
    "https://us-central1-festivalnmcp.cloudfunctions.net/createSession/",
    {
      method: "POST",
      body: JSON.stringify({ quantity: quantity, email: email, docId: docId }),
    }
  )
    .then((res) => res.json())
    .then((data) => data.url)
    .catch((err) => err);
};

export const sendEmailWithQR = async (
  qrCodes: string[],
  email: string,
  name: string
) => {
  return await fetch(
    "https://us-central1-festivalnmcp.cloudfunctions.net/sendEmailWithQR",
    {
      method: "POST",
      mode: "no-cors",
      body: JSON.stringify({ qrCodes: qrCodes, email: email, name: name }),
    }
  )
    .then((res) => res.json())
    .catch((err) => err);
};

export const sendEmailToNMCP = async (
  message: string,
  email: string,
  name: string,
  phoneNumber: string,
  subject: string
) => {
  return await fetch(
    "https://us-central1-festivalnmcp.cloudfunctions.net/sendEmailToNMCP",
    {
      method: "POST",
      mode: "no-cors",
      body: JSON.stringify({
        message: message,
        email: email,
        name: name,
        phoneNumber: phoneNumber,
        subject: subject,
      }),
    }
  )
    .then((res) => res.json())
    .catch((err) => err);
};

export const saveBlogPost = async (
  blogTitle: string,
  blogBody: string,
  blogCategory: string,
  blogDate: string,
  image: string | null | ArrayBuffer,
  authorName: string,
  authorFunction: string,
  authorImage: string | ArrayBuffer | null
) => {
  await addDoc(collection(base, "blogPosts"), {
    blogTitle: blogTitle,
    blogBody: blogBody,
    blogCategory: blogCategory,
    blogDate: blogDate,
    image: image,
    authorName: authorName,
    authorFunction: authorFunction,
    authorImage: authorImage,
  })
    .then((resp) => resp)
    .catch((e) => {
      throw e;
    });
};

export const getBlogPosts = async () => {
  let docsArray: any = [];
  const currentDoc = await getDocs(collection(base, "blogPosts"));
  currentDoc.forEach((doc) => {
    docsArray.push({...doc.data(), id: doc.id});
  });
  return docsArray;
};

export const getBlogPost = async (blogTitle: string) => {
  let docsArray: any = [];
  const currentDoc = await getDocs(collection(base, "blogPosts"));
  currentDoc.forEach((doc) => {
    docsArray.push({...doc.data(), id: doc.id});
  });

  const filteredDocsArray = docsArray.find(
    (doc: any) =>
      doc.blogTitle.replace(/ /g, "") ===
      decodeURIComponent(blogTitle.replace("/blog/", ""))
  );

  return filteredDocsArray;
};
